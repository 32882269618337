import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import './loader.scss';

const Loader = ({ ...props }) => {
  return <Spinner animation="border" {...props} />;
};

Loader.propTypes = {};

Loader.defaultProps = {};
export default Loader;
