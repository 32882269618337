import React, { useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import Button from 'atoms/Button';
import Loader from 'atoms/Loader';
import { fetcher } from 'hooks/useFetcher';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { dispatchGlobalNotificationError } from '../../GlobalNotification/actions';
import './sso.scss';
import { resolveDynamicString } from 'utils/resolve';

const SyncUserButton = ({ organizationId, defaultUserSync, dispatchGlobalNotificationError }) => {
  const t = useFormatMessage();
  const [syncing, setSyncing] = useState(false);
  const [userSync, setUserSync] = useState({ ...defaultUserSync });

  const syncMessage = useMemo(() => {
    if (userSync.status === 'failed') {
      return userSync.syncFinishedAt ? { code: 'azure-sync-failure-text', dynamicData: { dateTime: moment(userSync.syncFinishedAt).format('L LTS') } } : null;
    }
    if (userSync.lastSuccessfulSyncAt) {
      return { code: 'azure-sync-success-text', dynamicData: { dateTime: moment(userSync.lastSuccessfulSyncAt).format('L LTS') } };
    }
    return null;
  }, [userSync.status, userSync.syncFinishedAt, userSync.lastSuccessfulSyncAt]);

  const syncNow = async () => {
    try {
      setSyncing(true);
      const result = await fetcher(`/organizations/${organizationId}/sync/azure`);
      if (result.errors) {
        const errorCode = result.errors[0].code;
        const errorMessages = {
          AZURE_SYNC_NOT_ENABLED: t('global-notification-AZURE_SYNC_NOT_ENABLED'),
          INVALID_AZURE_SYNC_CONFIG: t('global-notification-INVALID_AZURE_SYNC_CONFIG'),
          USER_SYNC_IN_PROGRESS: t('global-notification-USER_SYNC_IN_PROGRESS'),
          AZURE_SYNC_USERS_FETCH_ERROR: t('global-notification-AZURE_SYNC_USERS_FETCH_ERROR')
        };
        if (errorMessages[errorCode]) {
          dispatchGlobalNotificationError([{ userMessage: errorMessages[errorCode] }]);
        }
        if (errorMessages[errorCode] === 'AZURE_SYNC_USERS_FETCH_ERROR') {
          setUserSync(userSync => ({ ...userSync, status: 'failed', syncFinishedAt: new Date().toISOString() }));
        }
      } else {
        setUserSync(userSync => ({ ...userSync, ...result }));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSyncing(false);
    }
  };

  return (
    <div className="user-sync">
      {syncing ? <Loader size="sm" /> : <span className={`user-sync__status-${userSync?.status}`}>{syncMessage ? resolveDynamicString(t(syncMessage.code), syncMessage.dynamicData) : ''}</span>}
      <Button onClick={syncNow} className={`user-sync__button`} disabled={syncing}>
        {t('azure-sync-btn-text')}
      </Button>
    </div>
  );
};

SyncUserButton.propTypes = {
  organizationId: PropTypes.string,
  defaultUserSync: PropTypes.shape({
    status: PropTypes.string,
    startedAt: PropTypes.string,
    syncFinishedAt: PropTypes.string,
    lastSuccessfulSyncAt: PropTypes.string
  }),
  dispatchGlobalNotificationError: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchGlobalNotificationError: bindActionCreators(dispatchGlobalNotificationError, dispatch)
  };
};

export default withRouter(connect(() => {}, mapDispatchToProps)(SyncUserButton));
