import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import sn from 'classnames';

import DynamicTextProcessor from 'atoms/DynamicTextProcessor/DynamicTextProcessor';
import './PageHeader.scss';

export default class PageHeader extends Component {
  render() {
    const { title, desc, variant, isDangerousHTML } = this.props;
    return (
      <Container>
        <Row className="justify-content-center">
          <Col lg={10} sm={12} xs="12">
            <div
              className={sn('PageHeader', {
                [`PageHeader--${variant}`]: variant
              })}
            >
              <h3>{title}</h3>
              {isDangerousHTML ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: desc
                  }}
                />
              ) : (
                <DynamicTextProcessor text={desc} />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  isDangerousHTML: PropTypes.bool,
  variant: PropTypes.string
};

PageHeader.defaultProps = {
  variant: '',
  isDangerousHTML: false
};
