import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { get, isEmpty } from 'lodash';

import IconWrapper from 'atoms/IconWrapper';
import DropDown from 'atoms/DropDown';
import Input from 'atoms/Input';
import Button from 'atoms/Button';
import Modal from 'atoms/Modal';
import RoleCheck from '../../RoleCheck';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { formatLanguages, getFilteredTimezone, dateValues } from 'utils/calculationUtilities';
import { getAllTimezones, fetchPublications } from '../../../actions';
import { updateAccountSettings, deleteMindzeedAccount } from '../actions';

import './general.scss';

const reducer = (state, action) => {
  const { key, value, eventKey: { id: dropDownKey } = {} } = action;
  switch (action.type) {
    case 'inputChange':
      return {
        ...state,
        [key]: value,
        notPristine: false
      };
    case 'dropDownChange':
      return {
        ...state,
        notPristine: false,
        [key]: dropDownKey
      };
    case 'initializeStateToPristine':
      return {
        ...state,
        notPristine: true
      };
    case 'initializeState': {
      const { name, _id, apiKey, settings: { timezone: { country, timezone }, dateFormat, defaultLangCode, ownerEmail, supportEmail } = {} } = action.payload;
      return {
        ...state,
        apiKey,
        accountId: _id,
        companyName: name,
        dateFormat,
        defaultLangCode,
        timezone,
        ownerEmail,
        supportEmail,
        timezoneCountry: country,
        notPristine: true
      };
    }
    case 'initializeFilteredTimezones':
      return {
        ...state,
        filteredTimezone: action.filteredTimezone
      };
    case 'getTimezones':
      return {
        ...state,
        filteredTimezone: action.payload
      };

    default:
      throw new Error();
  }
};

const GeneralSettings = ({
  dateValues,
  publications,
  timezonesCountry,
  timezones,
  getAllTimezones,
  updateAccountSettings,
  deleteMindzeedAccount,
  selectedOrgDetails,
  fetchPublications,
  selectedOrg,
  hasSelectedOrgUpdated
}) => {
  const t = useFormatMessage();
  const [deleteAccountConfirmationModal, setDeleteAccountConfirmationModal] = useState(false);
  const [state, dispatcher] = useReducer(reducer, { notPristine: true, userAccounDetails: {} });
  const { accountId, apiKey, supportEmail, companyName, ownerEmail, dateFormat, defaultLangCode, timezoneCountry, timezone, filteredTimezone, notPristine } = state;
  useEffect(() => {
    getAllTimezones();
    if (isEmpty(publications)) {
      fetchPublications();
    }
  }, []);

  useEffect(() => {
    if (hasSelectedOrgUpdated) {
      dispatcher({
        type: 'initializeStateToPristine'
      });
    }
  }, [hasSelectedOrgUpdated]);

  useEffect(() => {
    if (!isEmpty(selectedOrgDetails)) {
      const { settings: { timezone: { country } } = {} } = selectedOrgDetails;
      dispatcher({
        type: 'initializeState',
        payload: selectedOrgDetails
      });
      dispatcher({
        type: 'getTimezones',
        payload: getFilteredTimezone(timezones, country)
      });
    }
  }, [selectedOrgDetails]);

  const handleGeneralAccountSettingUpdate = () => {
    updateAccountSettings({
      organizationId: selectedOrg,
      payload: {
        ...(!isEmpty(supportEmail) ? { supportEmail } : {}),
        ...(!isEmpty(ownerEmail) ? { ownerEmail } : {}),
        name: companyName,
        dateFormat,
        defaultLangCode,
        timezoneCountry,
        timezone
      }
    });
  };

  const deleteAccount = () => {
    deleteMindzeedAccount({
      organizationId: selectedOrg
    });
    setDeleteAccountConfirmationModal(false);
  };

  return (
    <React.Fragment>
      <Container className="general-settings-container">
        <Row className="general-settings-container__row">
          <Col className="pl-0">{t('login/organization-code')}</Col>
          <Col className="pr-0 general-settings-container__row__flex-end  general-settings-container__copy-to-clipboard ">{selectedOrgDetails.code}</Col>
        </Row>
        <Row className="general-settings-container__row">
          <Col className="pl-0">{t('account-settings/account-id')}</Col>
          <Col className="pr-0 general-settings-container__row__flex-end  general-settings-container__copy-to-clipboard ">
            {accountId}
            <CopyToClipboard text={accountId}>
              <IconWrapper className="general-settings-container__row__flex-end__image" type="Clipboard" />
            </CopyToClipboard>
          </Col>
        </Row>
        <Row className="general-settings-container__row">
          <Col className="pl-0">{t('account-settings/api-key')}</Col>
          <Col className="pr-0 general-settings-container__row__flex-end  general-settings-container__copy-to-clipboard ">
            &#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;
            <CopyToClipboard text={apiKey}>
              <IconWrapper className="general-settings-container__row__flex-end__image" type="Clipboard" />
            </CopyToClipboard>
          </Col>
        </Row>
        <Row className="general-settings-container__row">
          <Col className="pl-0">{t('account-settings/company-name')}</Col>
          <Col className="pr-0 general-settings-container__row__flex-end">
            <Input
              onChange={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'companyName',
                  value: e.target.value
                })
              }
              value={companyName}
            />
          </Col>
        </Row>
        <Row className="general-settings-container__row">
          <Col className="pl-0">{t('account-settings/account-owner-email')}</Col>
          <Col className="pr-0 general-settings-container__row__flex-end">
            <Input
              onChange={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'ownerEmail',
                  value: e.target.value
                })
              }
              value={ownerEmail}
            />
          </Col>
        </Row>
        <Row className="general-settings-container__row">
          <Col className="pl-0">{t('account-settings/user-support-email')}</Col>
          <Col className="pr-0 general-settings-container__row__flex-end">
            <Input
              onChange={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'supportEmail',
                  value: e.target.value
                })
              }
              value={supportEmail}
            />
          </Col>
        </Row>
        <Row className="general-settings-container__row">
          <Col className="pl-0">{t('account-settings/default-language')}</Col>
          <Col className="pr-0 general-settings-container__row__flex-end">
            <DropDown
              onSelect={eventKey => {
                dispatcher({
                  type: 'dropDownChange',
                  key: 'defaultLangCode',
                  eventKey
                });
              }}
              key={accountId}
              heading={defaultLangCode}
              placeholder={t('common/select')}
              values={publications}
            />
          </Col>
        </Row>
        <Row className="general-settings-container__row">
          <Col className="pl-0">{t('account-settings/default-dateformat')}</Col>
          <Col className="pr-0 general-settings-container__row__flex-end">
            <DropDown
              onSelect={eventKey =>
                dispatcher({
                  type: 'dropDownChange',
                  key: 'dateFormat',
                  eventKey
                })
              }
              heading={dateFormat}
              placeholder={t('common/select')}
              values={dateValues}
            />
          </Col>
        </Row>
        <Row className="general-settings-container__row">
          <Col className="pl-0">{t('account-settings/default-timezone')}</Col>
          <Col className="pr-0 general-settings-container__row__flex-end timezone">
            <DropDown
              onSelect={eventKey => {
                dispatcher({
                  type: 'dropDownChange',
                  key: 'timezoneCountry',
                  eventKey
                }),
                  dispatcher({
                    type: 'getTimezones',
                    payload: getFilteredTimezone(timezones, eventKey.id)
                  });
              }}
              key={accountId}
              heading={timezoneCountry}
              placeholder={t('common/select')}
              values={timezonesCountry}
            />
            <DropDown
              onSelect={eventKey =>
                dispatcher({
                  type: 'dropDownChange',
                  key: 'timezone',
                  eventKey
                })
              }
              heading={timezone}
              key={timezoneCountry}
              placeholder={t('common/select')}
              values={filteredTimezone}
            />
          </Col>
        </Row>

        <RoleCheck
          allowed={['ADMIN']}
          component={
            <Row className="general-settings-container__row">
              <Col className="pl-0">
                {t('account-settings/delete-the-mindzeed-account')}
                <p className="general-settings-container__row__paragraph">{t('if-you-delete-the-account-content-and-userdata-will-be-gone-forever')}</p>
              </Col>
              <Col className="pr-0 general-settings-container__row__flex-end timezone">
                <Button onClick={() => setDeleteAccountConfirmationModal(true)} style={{ minWidth: '150px', height: '40px' }}>
                  {t('account-settings/delete-account')}
                </Button>
              </Col>
            </Row>
          }
        />

        <Row className="general-settings-container__row" style={{ border: 'none' }}>
          <Button notPristine={notPristine} onClick={handleGeneralAccountSettingUpdate} style={{ minWidth: '100px' }}>
            {t('common/save')}
          </Button>
        </Row>
      </Container>
      <Modal
        heading={t('common/modal/please-confirm')}
        modalBody={<p>{t('common/modal/delete-account-warning-text')}</p>}
        show={deleteAccountConfirmationModal}
        isAlert
        onSave={deleteAccount}
        onHide={() => setDeleteAccountConfirmationModal(false)}
      />
    </React.Fragment>
  );
};

GeneralSettings.propTypes = {
  dateValues: PropTypes.arrayOf(PropTypes.shape({})),
  publications: PropTypes.arrayOf(PropTypes.shape({})),
  timezones: PropTypes.arrayOf(PropTypes.shape({})),
  timezonesCountry: PropTypes.arrayOf(PropTypes.shape({})),
  getAllTimezones: PropTypes.func.isRequired,
  selectedOrg: PropTypes.string.isRequired,
  updateAccountSettings: PropTypes.func.isRequired,
  deleteMindzeedAccount: PropTypes.func.isRequired,
  fetchPublications: PropTypes.func.isRequired,
  hasSelectedOrgUpdated: PropTypes.bool.isRequired,
  selectedOrgDetails: PropTypes.shape({
    code: PropTypes.string,
    settings: PropTypes.shape({})
  })
};

GeneralSettings.defaultProps = {
  hasSelectedOrgUpdated: false
};

const mapStateToProps = ({ myAccountState, adminState, accountSettings }) => {
  const publications = get(adminState, 'publications', []);
  const formattedLanguageList = formatLanguages(publications);
  const { selectedOrgDetails, hasSelectedOrgUpdated } = accountSettings;
  return {
    timezones: myAccountState.timezones,
    timezonesCountry: myAccountState.timezonesCountry,
    dateValues,
    publications: formattedLanguageList,
    selectedOrgDetails,
    hasSelectedOrgUpdated
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllTimezones: bindActionCreators(getAllTimezones, dispatch),
    updateAccountSettings: bindActionCreators(updateAccountSettings, dispatch),
    deleteMindzeedAccount: bindActionCreators(deleteMindzeedAccount, dispatch),
    fetchPublications: bindActionCreators(fetchPublications, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralSettings));
