import React from 'react';
import PropTypes from 'prop-types';

const DynamicTextProcessor = ({ text }) => {
  const processText = text => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const lines = text.split('\n');

    return lines.map((line, lineIndex) => (
      <React.Fragment key={lineIndex}>
        {line.split(urlRegex).map((part, index) => {
          if (urlRegex.test(part)) {
            return (
              <a href={part} target="_blank" rel="noopener noreferrer" key={index}>
                {part}
              </a>
            );
          }
          return part;
        })}
        {lineIndex < lines.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return <p>{processText(text)}</p>;
};

DynamicTextProcessor.propTypes = {
  text: PropTypes.string.isRequired
};
export default DynamicTextProcessor;
